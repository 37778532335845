import React from 'react'
import { Link, useNavigate } from "react-router-dom";


const RequestView = ({request}) => {

const navigate = useNavigate();

	return(
	<div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{request.userTicketOpenName}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{request.userTicketOpenDateTime}</h6>
                <p class="card-text">{request.memo}</p>

                <p class="card-text">{request.id}</p>
                <p class="card-text">{request.createDt}</p>
                <p class="card-text">{request.modifyDt}</p>
                <p class="card-text">{request.ticketOpenYn}</p>
                <p class="card-text">{request.ticketOpenId}</p>
                <p class="card-text">{request.siteLoginYn}</p>
                <p class="card-text">{request.siteLoginId}</p>
                <p class="card-text">{request.playCount}</p>

		<Link to="ed">
			<button type="button" class="btn btn-primary">수정</button>
		</Link> 
		<button type="button" class="btn btn-secondary" onClick={() => navigate(-1)}>뒤로</button>
              </div>
            </div>
        </div>
	)
}

export default RequestView
