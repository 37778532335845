import React from 'react'
import { Routes, Route, Link, useParams } from "react-router-dom";

const RequestList = ({requests}) => {
	return(
	<div>
          {requests.map((request) => (
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{request.userTicketOpenName}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{request.userTicketOpenDateTime}</h6>

                <p class="card-text">{request.memo}</p>
		<Link to={request.id}>
			more...
		</Link>
              </div>
            </div>
          ))}
        </div>
	)
}

export default RequestList
