import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, List, Space } from "antd";

import { Spin, Typography, Divider, Descriptions } from "antd";
const { Paragraph, Title, Text } = Typography;

const goods = [
    {
        goodInfos: [
            "뮤지컬 젠틀맨스 가이드 대구",
            "https://rucola.notion.site/127a394860e0805f953bc0db9c1566a9?pvs=4",
        ],
    },
    {
        goodInfos: [
            "뮤지컬 젠틀맨스 가이드 대구",
            "https://rucola.notion.site/127a394860e0805f953bc0db9c1566a9?pvs=4",
        ],
    },
    {
        goodInfos: [
            "뮤지컬 젠틀맨스 가이드 대구",
            "https://rucola.notion.site/127a394860e0805f953bc0db9c1566a9?pvs=4",
        ],
    },
    {
        goodInfos: [
            "뮤지컬 젠틀맨스 가이드 대구",
            "https://rucola.notion.site/127a394860e0805f953bc0db9c1566a9?pvs=4",
        ],
    },
];

const UrlList = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        // API 호출 대신 하드코딩된 데이터를 사용
        setData(goods);
    }, []);

    // useEffect(() => {
    //     const timestamp = +new Date();
    //     // JSON 데이터를 가져오는 API 요청 (예시)
    //     axios
    //         .get("/goods.json?rnd=" + timestamp)
    //         .then((response) => {
    //             // setData(response.data);
    //
    //             const d = response.data.map((item) => ({
    //                 value: item[0],
    //                 text: item[1],
    //             }));
    //
    //             setData(d);
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching data:", error);
    //         });
    // }, []);
    return (
        <div className="example">
            <br />
            <Space
                direction="vertical"
                align="end"
                size="2"
                style={{ width: "100%", justifyContent: "center" }}
            >
                <Title id="#Seat" level={4}>
                    좌석
                </Title>
                <Title level={5}>-------------</Title>
                <Title level={5} style={{ lineHeight: "1.8" }}>
                    <Space>
                        <a href="https://rucola.notion.site/20-128a394860e080d4a332f1b857407991?pvs=4">
                            20집 발매기념 조용필 대구
                        </a>
                        <Button
                            htmlType="button"
                            href="https://rucola.notion.site/20-128a394860e080d4a332f1b857407991?pvs=4"
                        >
                            보기
                        </Button>
                    </Space>
                </Title>

                <Title level={5} style={{ lineHeight: "1.8" }}>
                    <Space>
                        <a href="https://rucola.notion.site/20-128a394860e080d4a332f1b857407991?pvs=4">
                            뮤지컬 드라이 플라워
                        </a>
                        <Button
                            htmlType="button"
                            href="https://rucola.notion.site/20-128a394860e080d4a332f1b857407991?pvs=4"
                        >
                            보기
                        </Button>
                    </Space>
                </Title>

                <List
                    dataSource={goods}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                justifyContent: "flex-end", // 오른쪽 정렬
                                border: "none", // 라인 제거
                                padding: "0 0", // 각 항목의 패딩 조정
                            }}
                        >
                            <Title level={5} style={{ lineHeight: "1.8" }}>
                                <Space>
                                    <a href={item.goodInfos[1]}>
                                        {item.goodInfos[0]}
                                    </a>
                                    <Button
                                        htmlType="button"
                                        href={item.goodInfos[1]}
                                    >
                                        보기
                                    </Button>
                                </Space>
                            </Title>
                        </List.Item>
                    )}
                />
                <Title level={5}>-------------</Title>
            </Space>
        </div>
    );

    // return (
    //     <div style={{ padding: "20px", maxWidth: "400px", margin: "0 auto" }}>
    //         <List
    //             dataSource={urlData}
    //             renderItem={(item) => (
    //                 <List.Item
    //                     style={{
    //                         display: "flex",
    //                         justifyContent: "space-between",
    //                         alignItems: "center",
    //                     }}
    //                 >
    //                     <span
    //                         style={{
    //                             color: "#1890ff",
    //                             textAlign: "right",
    //                             flex: 1,
    //                         }}
    //                     >
    //                         {item.title}
    //                     </span>
    //                     <Button type="primary" size="small">
    //                         보기
    //                     </Button>
    //                 </List.Item>
    //             )}
    //         />
    //     </div>
    // );
};

export default UrlList;
